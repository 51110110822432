// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { NewTransactionRequest } from "./NewTransactionRequest";

import { NewTransactionResponse } from "./NewTransactionResponse";

import { ProblemJson } from "./ProblemJson";

import { NodeProblemJson404 } from "./NodeProblemJson404";

import { NodeProblemJson409 } from "./NodeProblemJson409";

import { NodeProblemJson502 } from "./NodeProblemJson502";

import { PartyConfigurationFaultPaymentProblemJson } from "./PartyConfigurationFaultPaymentProblemJson";

import { CalculateFeeRequest } from "./CalculateFeeRequest";

import { CalculateFeeResponse } from "./CalculateFeeResponse";

/****************************************************************
 * newTransaction
 */

// Request type definition
export type NewTransactionT = r.IPostApiRequestType<
  {
    readonly "x-correlation-id": string;
    readonly "x-client-id-from-client": string;
    readonly recaptchaResponse: string;
    readonly body: NewTransactionRequest | ReadableStream<Uint8Array> | Buffer;
  },
  "Content-Type",
  never,
  | r.IResponseType<200, NewTransactionResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, NodeProblemJson404, never>
  | r.IResponseType<409, NodeProblemJson409, never>
  | r.IResponseType<502, NodeProblemJson502, never>
  | r.IResponseType<503, PartyConfigurationFaultPaymentProblemJson, never>
>;

export const newTransactionDefaultResponses = {
  200: NewTransactionResponse,
  400: ProblemJson,
  404: NodeProblemJson404,
  409: NodeProblemJson409,
  502: NodeProblemJson502,
  503: PartyConfigurationFaultPaymentProblemJson
};

export type NewTransactionResponsesT<
  A0 = NewTransactionResponse,
  C0 = NewTransactionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  502: t.Type<A4, C4>;
  503: t.Type<A5, C5>;
};

export function newTransactionDecoder<
  A0 = NewTransactionResponse,
  C0 = NewTransactionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
>(
  overrideTypes:
    | Partial<
        NewTransactionResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<409, A3, never>
  | r.IResponseType<502, A4, never>
  | r.IResponseType<503, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((newTransactionDefaultResponses as unknown) as NewTransactionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, never>(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        never
      >(409, type[409])) as r.ResponseDecoder<r.IResponseType<409, A3, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A4, never>>;

  const d503 = (type[503].name === "undefined"
    ? r.constantResponseDecoder<undefined, 503, never>(503, undefined)
    : r.ioResponseDecoder<
        503,
        typeof type[503]["_A"],
        typeof type[503]["_O"],
        never
      >(503, type[503])) as r.ResponseDecoder<r.IResponseType<503, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
        d409
      ),
      d502
    ),
    d503
  );
}

// Decodes the success response with the type defined in the specs
export const newTransactionDefaultDecoder = () => newTransactionDecoder();

/****************************************************************
 * calculateFees
 */

// Request type definition
export type CalculateFeesT = r.IPostApiRequestType<
  {
    readonly bearerAuth: string;
    readonly id: string;
    readonly maxOccurrences?: number;
    readonly "x-transaction-id-from-client": string;
    readonly body: CalculateFeeRequest | ReadableStream<Uint8Array> | Buffer;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, CalculateFeeResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<504, ProblemJson, never>
>;

export const calculateFeesDefaultResponses = {
  200: CalculateFeeResponse,
  400: ProblemJson,
  404: ProblemJson,
  500: ProblemJson,
  504: ProblemJson
};

export type CalculateFeesResponsesT<
  A0 = CalculateFeeResponse,
  C0 = CalculateFeeResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
  504: t.Type<A4, C4>;
};

export function calculateFeesDecoder<
  A0 = CalculateFeeResponse,
  C0 = CalculateFeeResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<CalculateFeesResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<500, A3, never>
  | r.IResponseType<504, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((calculateFeesDefaultResponses as unknown) as CalculateFeesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const calculateFeesDefaultDecoder = () => calculateFeesDecoder();
